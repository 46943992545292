<template>
    <div>
        <v-frappe-chart
            v-bind="{...$props, ...$attrs}"
        />
    </div>
</template>

<script>
import { VFrappeChart } from "vue-frappe-chart"

export default {
    name: "Chart",
    components: {
        VFrappeChart
    },
    props: {
        ...VFrappeChart.props
        // https://carterbourette.ca/vue-frappe-chart/package/#frappe-charts-vs-vue-frappe
    }
}
</script>
