import {
    Banknote,
    DollarSign,
    Files,
    PartyPopper,
    PlusSquare,
    StickyNote,
    ThumbsDown,
    ThumbsUp,
    Verified
} from "lucide-vue"

export default {
    Banknote,
    DollarSign,
    Files,
    PartyPopper,
    PlusSquare,
    StickyNote,
    ThumbsDown,
    ThumbsUp,
    Verified
}
