<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:empty-value><slot name="empty-value"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:required="{ required }"><slot name="required" :required="required"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>

        <document-upload-input
            :id="id"
            :name="name"
            :url="url"
            :error="error"
            :uploading="uploading"
            :uploaded="uploaded"
            :replaceable="replaceable"
            :deletable="deletable"
            :accepts="accepts"
            :glyph="glyph"
            :delete-buttonititle="deleteButtonTitle"
            :replace-title="replaceTitle"
            :disabled="disabled"
            v-on="$listeners"
        >
            <template v-slot:dragging-title>
                <slot name="dragging-title"></slot>
            </template>
            <template v-slot:empty-title>
                <slot name="empty-title"></slot>
            </template>
            <template v-slot:empty-button-title>
                <slot name="empty-button-title"></slot>
            </template>
            <template v-slot:display>
                <slot name="display"></slot>
            </template>
            <template v-slot:instructions>
                <slot name="instructions"></slot>
            </template>
        </document-upload-input>
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import FormGroup from "./FormGroup"
import DocumentUploadInput from "./DocumentUploadInput"

export default {
    name: "FormDocumentUploadInput",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        DocumentUploadInput
    },
    methods: {
    },
    computed: {
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        }
    },
    props: {
        ...FormGroup.props,
        ...DocumentUploadInput.props
    }
}
</script>
