import { render, staticRenderFns } from "./AppIcon.vue?vue&type=template&id=7ae6fefa"
import script from "./AppIcon.vue?vue&type=script&lang=js"
export * from "./AppIcon.vue?vue&type=script&lang=js"
import style0 from "./AppIcon.vue?vue&type=style&index=0&id=7ae6fefa&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports