<template>
    <upload-input
        v-bind="upload_input_props"
        :delete-button-title="delete_button_title"
        :replace-title="replace_title"
        v-on="$listeners"
    >
        <template v-slot:dragging-title>
            <slot name="dragging-title">{{ translate("Drop your document to upload it") }}</slot>
        </template>
        <template v-slot:empty-title>
            <slot name="empty-title">{{ translate("Drag your document here, or use the button below") }}</slot>
        </template>
        <template v-slot:empty-button-title>
            <slot name="empty-button-title">{{ translate("Select document...") }}</slot>
        </template>
        <template v-slot:instructions>
            <slot name="instructions"></slot>
        </template>
        <template v-slot:display>
            <slot name="display"><app-icon glyph="file" size="4x" /></slot>
        </template>
        <base-link
            v-if="viewable"
            :href="url"
            target="ccc-attachment"
            class="btn s-circle nibnut-document-view"
            @click.stop
        >
            <app-icon glyph="external-link" />
        </base-link>
    </upload-input>
</template>

<script>
import UploadInput from "./UploadInput"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import AppIcon from "@/nibnut/components/AppIcon"

export default {
    components: {
        UploadInput,
        BaseLink,
        AppIcon
    },
    computed: {
        upload_input_props () {
            const { glyph, deleteButtonTitle, replaceTitle, ...upload_input_props } = this.$props
            return upload_input_props
        },
        delete_button_title () {
            return this.deleteButtonTitle || this.translate("Delete document...")
        },
        replace_title () {
            return this.replaceTitle || this.translate("Replace document...")
        },
        viewable () {
            return !!this.url && !this.url.match(/^file:\/\//)
        }
    },
    props: {
        ...UploadInput.props
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-document-uploader {
    .nibnut-document-view {
        position: absolute;
        bottom: -18px;
        right: -16px;
        z-index: $zindex-3;
    }
}
</style>
