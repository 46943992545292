<template>
    <div class="base-time-input">
        <input-group
            glyph="clock"
            glyph-position="right"
        >
            <template v-slot:left_addon><slot name="left_addon"></slot></template>
            <vue-timepicker
                ref="field"
                :id="identifier"
                :value="display_value"
                v-bind="time_picker_props"
                @change="set_time"
            />
        </input-group>
    </div>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import is_alpha_numerical_input from "@/nibnut/mixins/IsAlphaNumericalInput"
import handles_dates from "@/nibnut/mixins/HandlesDates"

import InputGroup from "@/nibnut/components/Inputs/InputGroup"
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue"

export default {
    name: "BaseTimeInput",
    mixins: [is_nibnut_component, is_alpha_numerical_input, handles_dates],
    components: {
        InputGroup,
        VueTimepicker
    },
    methods: {
        set_time (time_data) {
            let date = this.standardized_date(this.value) || this.$dayjs()
            date = date.second(parseInt(time_data.data.s) || 0)
            date = date.minute(parseInt(time_data.data.m) || 0)
            date = date.hour(parseInt(time_data.data.H) || 0)
            this.$emit("input", date, this.name)
        }
    },
    computed: {
        time_picker_props () {
            const { id, name, value, editable, required, disabled, saving, error, ...time_picker_props } = this.$props
            return time_picker_props
        },
        display_value () {
            if(!this.value) return ""
            return this.standardized_date(this.value).format(this.format)
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        hideClearButton: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: " "
        },
        format: {
            type: String,
            default: "hh:mm A"
        },
        editable: {
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    }
}
</script>
