import handles_errors from "./HandlesErrors"

const handles_saving = {
    mixins: [handles_errors],
    methods: {
        standardize_field_save_value (value, field) {
            if(value instanceof Date) {
                if(field.match(/_at$/)) return this.$dayjs(value).format("YYYY-MM-DD HH:mm:ss")
                return this.$dayjs(value).format("YYYY-MM-DD")
            }
            return value
        },
        save_data_for_record_id (entity, record_id, data) {
            const fields = Object.keys(data)
            fields.forEach(field => {
                if(!this.saving_field_ids[record_id]) this.$set(this.saving_field_ids, record_id, [])
                if(this.saving_field_ids[record_id].indexOf(field) < 0) this.saving_field_ids[record_id].push(field)
                this.clear_error(field)
            })

            if(!record_id) {
                return this.$store.dispatch("CREATE_RECORD", {
                    entity: entity,
                    data
                }).then(record => {
                    this.saved_data(record, true, data)
                    return record
                }).catch(this.receive_error).then(record => {
                    fields.forEach(field => {
                        const index = this.saving_field_ids[record_id].indexOf(field)
                        if(index >= 0) this.saving_field_ids[record_id].splice(index, 1)
                    })
                    return record
                })
            }
            return this.$store.dispatch("RECORD_SAVE", {
                entity: entity,
                id: record_id,
                data
            }).then(record => {
                this.saved_data(record, false, data)
                return record
            }).catch(this.receive_error).then(record => {
                fields.forEach(field => {
                    const index = this.saving_field_ids[record_id].indexOf(field)
                    if(index >= 0) this.saving_field_ids[record_id].splice(index, 1)
                })
                return record
            })
        },
        save_field_for_record_id (entity, record_id, value, field) {
            return this.save_data_for_record_id(entity, record_id, { [field]: this.standardize_field_save_value(value, field) })
        },
        save_field (value, field) {
            return this.save_field_for_record_id(this.entity, this.record_id, value, field)
        },
        save_date_field (date, field, format = "YYYY-MM-DD") {
            if(!(date instanceof Date)) date = this.$dayjs(date)
            this.save_field(date.format(format), field)
        },
        saving (field = "", record_id = 0) {
            if(!field) return !!this.saving_field_ids[record_id || this.record_id] && !!this.saving_field_ids[record_id || this.record_id].length
            return !!this.saving_field_ids[record_id || this.record_id] && (this.saving_field_ids[record_id || this.record_id].indexOf(field) >= 0)
        },
        saved_data (record, is_new, data) {
        }
    },
    data () {
        return {
            record_id: 0,
            saving_field_ids: {},

            // NEEDS TO BE OVERRIDEN
            entity: ""
        }
    }
}

export default handles_saving
