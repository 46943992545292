const routes = [
    { path: "/", name: "home", component: () => import("@/views/dashboard/View"), meta: { login_required: true } },
    { path: "/settings", name: "settings", component: () => import("@/views/settings/Edit"), meta: { admin_route: true } },
    { path: "/profile", name: "profile", component: () => import("@/views/user/Edit"), meta: { login_required: true } },
    { path: "/drafts", name: "draft.list", component: () => import("@/views/dashboard/View"), meta: { login_required: true } },
    { path: "/draft/:id?", name: "draft.edit", component: () => import("@/views/draft/Edit"), meta: { login_required: true } },
    { path: "/users", name: "user.list", component: () => import("@/views/user/List"), meta: { admin_route: true, scroll_position: true } },
    { path: "/user/:id?", name: "user.edit", component: () => import("@/views/user/Edit"), meta: { admin_route: true } }
]

if(process.env.NODE_ENV !== "production") {
    routes.unshift({ path: "/ui-catalog", name: "ui-catalog", component: () => import("@/views/UiCatalog"), meta: { login_required: false } })
}

export default routes
