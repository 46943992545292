<template>
    <component
        :is="icon"
        :size="standardized_size"
        v-bind="svg_props"
        :class="`lucide-icon-${size}`"
        :title="alt"
        :aria-label="alt"
        :role="role"
    />
</template>

<script type="text/javascript">
import {
    AlertTriangle,
    AlignCenter,
    AlignJustify,
    AlignLeft,
    AlignRight,
    ArrowDownCircle,
    Bold,
    Bug,
    Calendar,
    Check,
    CheckCircle,
    CheckCircle2,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    Clipboard,
    ClipboardX,
    ClipboardCheck,
    Clock,
    ExternalLink,
    Eye,
    EyeOff,
    File,
    FileX2,
    HelpCircle,
    Home,
    Image,
    Italic,
    Link,
    List,
    ListOrdered,
    Locate,
    Menu,
    Minus,
    MoreVertical,
    Mountain,
    Pencil,
    Plus,
    Quote,
    Redo,
    Search,
    Settings,
    SortAsc,
    SortDesc,
    Strikethrough,
    Trash,
    Undo,
    Unlink,
    UploadCloud,
    User,
    Users,
    X,
    XOctagon,
    XSquare
} from "lucide-vue"
import * as custom_icons from "@/custom/mixins/CustomIcons"

const icons = {
    AlertTriangle,
    AlignCenter,
    AlignJustify,
    AlignLeft,
    AlignRight,
    ArrowDownCircle,
    Bold,
    Bug,
    Calendar,
    Check,
    CheckCircle,
    CheckCircle2,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    Clipboard,
    ClipboardX,
    ClipboardCheck,
    Clock,
    ExternalLink,
    Eye,
    EyeOff,
    File,
    FileX2,
    HelpCircle,
    Home,
    Image,
    Italic,
    Link,
    List,
    ListOrdered,
    Locate,
    Menu,
    Minus,
    MoreVertical,
    Mountain,
    Pencil,
    Plus,
    Quote,
    Redo,
    Search,
    Settings,
    SortAsc,
    SortDesc,
    Strikethrough,
    Trash,
    Undo,
    Unlink,
    UploadCloud,
    User,
    Users,
    X,
    XOctagon,
    XSquare,
    ...custom_icons
}

const camel_case = (text) => {
    if(!text) return ""
    const snake_case = text.toLowerCase().replace(/[-_\s.]+(.)?/g, (unused, character) => character.toUpperCase())
    return snake_case.substring(0, 1).toUpperCase() + snake_case.substring(1)
}

export default {
    name: "AppIcon",
    computed: {
        icon () {
            return icons[camel_case(this.glyph)]
        },
        svg_props () {
            const { glyph, size, ...svg_props } = this.$props
            return svg_props
        },
        standardized_size () {
            if(typeof this.size === "number") return this.size
            if(this.size === "xs") return 10
            if(this.size === "sm") return 12
            if(this.size === "lg") return 24
            if(this.size === "2x") return 32
            if(this.size === "3x") return 48
            if(this.size === "4x") return 64
            if(this.size === "5x") return 80
            return 16
        }
    },
    props: {
        glyph: {
            type: String,
            required: true
        },
        size: {
            validator: prop => {
                return !prop || (typeof prop === "number") || !!prop.match(/^(xs|sm|lg|2x|3x|4x|5x)$/i)
            },
            default: ""
        },
        color: {
            type: String,
            default: "currentColor"
        },
        strokeWidth: {
            type: Number,
            default: 1
        },
        alt: {
            type: String,
            default: ""
        },
        role: {
            type: String,
            default: "img"
        }
    }
}
</script>

<style lang="scss">
.lucide-icon.lucide-icon- {
    position: relative;
    top: 2px;
}
</style>
