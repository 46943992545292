<template>
    <form-group
        v-bind="form_group_props"
        class="nibnut-document-multiple-uploader"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:empty-value><slot name="empty-value"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:required="{ required }"><slot name="required" :required="required"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>

        <div class="columns">
            <div v-if="!disabled" class="column col-auto">
                <document-upload-input
                    :id="id"
                    :name="name"
                    url=""
                    :error="error"
                    :uploading="uploading"
                    :uploaded="uploaded"
                    :replaceable="false"
                    :deletable="false"
                    :accepts="accepts"
                    :glyph="glyph"
                    :disabled="false"
                    v-on="$listeners"
                >
                    <template v-slot:dragging-title>
                        <slot name="dragging-title"></slot>
                    </template>
                    <template v-slot:empty-title>
                        <slot name="empty-title"></slot>
                    </template>
                    <template v-slot:empty-button-title>
                        <slot name="empty-button-title"></slot>
                    </template>
                    <template v-slot:instructions>
                        <slot name="instructions"></slot>
                    </template>
                </document-upload-input>
            </div>
            <div
                v-for="document in documents"
                :key="document.id"
                class="column col-auto"
            >
                <div class="nibnut-document-display">
                    <base-link
                        v-if="viewable(document)"
                        :href="document.url"
                        target="ccc-attachment"
                        @click.stop
                    >
                        <slot name="display" :document="document">
                            <img v-if="!!document.icon" :src="document.icon" />
                            <app-icon v-else glyph="external-link" />
                            <div v-if="document.name" class="text-small p-1 mt-1">
                                {{ document.name }}
                            </div>
                        </slot>
                    </base-link>
                    <span v-else>
                        <slot name="display" :document="document">
                            <img v-if="!!document.icon" :src="document.icon" />
                            <app-icon v-else glyph="file" />
                            <div v-if="document.name" class="text-small p-1 mt-1">
                                {{ document.name }}
                            </div>
                        </slot>
                    </span>
                    <default-button
                        v-if="deletable && !disabled"
                        flavor="link"
                        color="error"
                        size="sm"
                        @click.stop.prevent="$emit('clear', document)"
                    >
                        <app-icon
                            glyph="x"
                            :title="translate('Delete file...')"
                        />
                    </default-button>
                </div>
            </div>
        </div>
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import FormGroup from "./FormGroup"
import DocumentUploadInput from "./DocumentUploadInput"
import DefaultButton from "../Buttons/DefaultButton"
import BaseLink from "../Links/BaseLink"
import AppIcon from "../AppIcon"

export default {
    name: "FormDocumentMultipleUploadsInput",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        DocumentUploadInput,
        DefaultButton,
        AppIcon,
        BaseLink
    },
    methods: {
        viewable (document) {
            return !!document && !!document.url && !document.url.match(/^file:\/\//)
        }
    },
    computed: {
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        }
    },
    props: {
        ...FormGroup.props,
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        documents: {
            type: Array,
            default () {
                return []
            }
        },
        error: {
            type: String,
            default: ""
        },
        uploading: {
            type: Boolean,
            default: false
        },
        uploaded: {
            type: Number,
            default: 0
        },
        deletable: {
            type: Boolean,
            default: true
        },
        accepts: { // mime types
            type: String,
            default: ""
        },
        glyph: {
            type: String,
            default: "upload-cloud"
        },
        deleteButtonTitle: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-document-multiple-uploader {
    & > .columns {
        .nibnut-document-uploader,
        .nibnut-document-display {
            width: 5rem;
            height: 5rem;
        }
        .nibnut-document-display {
            position: relative;
            border: 2px dashed $success-color;
            background: rgba($success-color, 0.05);
            & > a, & > span {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;

                div {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 100%;
                }
            }
            & > button {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}
</style>
