<template>
    <div
        :class="{'active': !!shown}"
        class="modal authentication"
    >
        <dismiss-link
            class="modal-overlay"
        />
        <div v-if="fallback_login_method" class="modal-container">
            <div class="modal-header">
                <div class="modal-title h5">
                    <app-logo />
                </div>
            </div>
            <div class="modal-body">
                <div id="google-login-btn"></div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import profile_utilities from "@/nibnut/mixins/ProfileUtilities"

import AppLogo from "@/nibnut/components/AppLogo"
import DismissLink from "@/nibnut/components/ModalDialog/DismissLink"

export default {
    mixins: [profile_utilities],
    components: {
        AppLogo,
        DismissLink
    },
    watch: {
        shown: "reset",
        app_context: "reset",
        profile_id: "maybe_dismiss"
    },
    methods: {
        bootstrap_google () {
            if(!window.google) setTimeout(() => { this.bootstrap_google() }, 100)
            else {
                window.google.accounts.id.initialize({
                    client_id: this.app_context.settings.google_client_id,
                    context: "signin",
                    auto_select: true,
                    itp_support: true,
                    callback: this.login_or_signup_google_user
                })
                window.google.accounts.id.prompt((notification) => {
                    if(notification.isNotDisplayed() || notification.isSkippedMoment()) {
                        // try next provider if OneTap is not displayed or skipped
                        this.fallback_login_method = true
                        setTimeout(() => {
                            const container = document.getElementById("google-login-btn")
                            window.google.accounts.id.renderButton(container, {
                                theme: "filled_black",
                                shape: "pill",
                                text: "continue_with"
                            })
                        }, 200)
                    }
                })
            }
        },
        reset () {
            if(this.shown && this.app_context && this.app_context.settings) this.bootstrap_google()
        },
        login_or_signup_google_user (response) {
            this.$store.dispatch("LOGIN", response).then(() => {
                this.$store.dispatch("UNREQUEST_LOGIN")
            }).catch(this.receive_error)
        },
        dismiss () {
            if(this.dismissable) this.$store.dispatch("UNREQUEST_LOGIN")
        },
        maybe_dismiss () {
            if(this.profile_id) this.shown = false
        }
    },
    computed: {
        ...mapState(["profile_id", "login_request", "app_context"]),
        shown: {
            get () {
                return !this.profile_id && !!this.login_request && !!this.login_request.panel_id
            },
            set (show) {
                if(!show) this.dismiss()
            }
        },
        dismissable () {
            if(this.$route.meta) return !this.$route.meta.admin_route && !this.$route.meta.login_required
            // eslint-disable-next-line
            return !!process.env.VUE_APP_DEFAULT_PUBLIC_PATH && process.env.VUE_APP_DEFAULT_PUBLIC_PATH.match(/^\//)
        }
    },
    props: {
        labelLess: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            current_panel_id: "login",
            email: "",
            fallback_login_method: false
        }
    }
}
</script>

<style lang="scss">
.modal.authentication {
    & > .modal-container {
        overflow: hidden;

        .modal-header {
            .app-logo {
                max-height: 5rem;
                margin: 0 auto;
            }
        }
        .modal-body {
            #google-login-btn {
                display: flex;
                justify-content: center;
            }
            & > .loader-container {
                text-align: center;

                & > .loader {
                    margin-top: 3rem;
                    margin-bottom: 3rem;
                }
            }
        }
        .modal-footer {
            text-align: center;
        }
    }
}
</style>
